import React from 'react';
import styled from 'styled-components';

export class DocumentLoadingMessage extends React.Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    // delayed revela
    setTimeout(() => this.setState({ visible: true }), 1000);
  }

  render() {
    const { visible } = this.state;
    const { name } = this.props;
    return (
      visible && (
        <Wrapper>
          <p>Loading...</p>
          <NameText>{name}</NameText>
        </Wrapper>
      )
    );
  }
}

export const DocumentErrorMessage = () => (
  <Wrapper>Error occured when loading document</Wrapper> // eslint-disable-line
);

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  p {
    margin: 0;
  }
`;

const NameText = styled.p`
  &&& {
    margin-top: 10px;
    font-weight: 500;
    font-size: 24px;
  }
`;
