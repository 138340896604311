import React from 'react';
import styled from 'styled-components';
import AlertIcon from '@material-ui/icons/PriorityHigh';

export const SearchWarningMessage = () => (
  <Wrapper>
    <StyledAlertIcon />
    <Content className="alert-content">
      Search mode may cause some performance issues and therefore is not adviced
      to leave on when browsing the document.
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: white;
  z-index: 2000;

  &:hover .alert-content {
      display: block;
    }
  }

  @media (max-width: 450px) {
    top: auto;
    bottom: 5px;
  }
`;

const StyledAlertIcon = styled(AlertIcon)`
  display: block;
  padding: 5px;
  align-self: flex-start;
  box-sizing: content-box;
`;

const Content = styled.div`
  display: none;
  padding: 12px 12px 12px 0;
  max-width: 310px;
`;
