import React from 'react';
import styled from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './common/theme';

import PdfViewer from './pdf-magazine/PdfViewer';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <StyledWrapper>
      <PdfViewer />
    </StyledWrapper>
  </MuiThemeProvider>
);

const StyledWrapper = styled.div`
  height: 100%;

  @media print {
    margin-top: 0;
    button {
      display: none !important;
    }
  }
`;

export default App;
