/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { isSafari } from 'react-device-detect';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { magazine } from './common/ducks';
import Page from './Page';

class Magazine extends React.Component {
  state = {
    renderMode: 'canvas',
    ratiosByPage: {},
  };

  transformRef = React.createRef();

  prevTouchPoint = null;

  componentDidUpdate(prevProps, prevState) {
    const {
      pageNumber,
      isSearchMode,
      zoom,
      setOnePager,
      viewportDimensions,
      onePagerMode,
    } = this.props;
    const { ratiosByPage } = this.state;

    // Toggle onePager id dynamic mode selected
    if (
      pageNumber !== prevProps.pageNumber ||
      ratiosByPage[pageNumber] !== prevState.ratiosByPage[pageNumber] ||
      viewportDimensions !== prevProps.viewportDimensions
    ) {
      const { width, height } = viewportDimensions;
      let onePager;
      if (onePagerMode === 0 || onePagerMode === 2) onePager = false;
      else if (onePagerMode === 1) onePager = true;
      else if (onePagerMode === 3)
        onePager = width / height < 1 || ratiosByPage[pageNumber] > 1;
      else onePager = false;
      setOnePager(onePager);
    }

    // Update url if pageNumber changed
    if (pageNumber !== prevProps.pageNumber && pageNumber >= 0) {
      const url = new URL(window.location.href);
      url.searchParams.set('page', pageNumber);
      window.history.replaceState(null, '', url.href);
    }

    // refresh required to place search highlights correctly
    if (isSearchMode && prevProps.pageNumber !== pageNumber) {
      // Time used because of animation
      setTimeout(() => this.forceUpdate(), 350);
    }

    if (!zoom && prevProps.pageNumber !== pageNumber && isSafari) {
      this.setState({
        renderMode: 'canvas',
      });
    }
  }

  handleTouchStart = event => {
    if (!event.touches || event.touches.length === 0) return;
    const touch = event.touches[0];
    const { screenX, screenY } = touch;
    this.prevTouchPoint = {
      x: screenX,
      y: screenY,
    };
  };

  handleTouchEnd = () => {
    this.prevTouchPoint = null;
  };

  handleTouchMove = event => {
    const { zoom, previousPage, nextPage } = this.props;

    // Handle swipes
    if (event.touches && event.touches.length === 1 && this.prevTouchPoint) {
      const touch = event.touches[0];
      const { screenX } = touch;
      // not zoomed -> swipe pages
      if (!zoom) {
        if (screenX - this.prevTouchPoint.x < -50) {
          nextPage();
          this.prevTouchPoint = null;
        } else if (screenX - this.prevTouchPoint.x > 50) {
          previousPage();
          this.prevTouchPoint = null;
        }
      }
    }
  };

  handleRatioChange(pageNumber, ratio) {
    const { ratiosByPage } = this.state;

    if (ratio !== ratiosByPage[pageNumber]) {
      this.setState(state => ({
        ratiosByPage: { ...state.ratiosByPage, [pageNumber]: ratio },
      }));
    }
  }

  render() {
    const {
      pageNumber,
      backwards,
      numberOfPages,
      onePager,
      viewportDimensions,
      isSmallScreen,
      withAnimation,
      zoom,
      zoomScale,
      onClick,
      toggleZoom,
      enhance,
    } = this.props;
    const { ratiosByPage, renderMode } = this.state;

    const leftPage = onePager ? pageNumber : Math.floor(pageNumber / 2) * 2;
    const leftSideRatio = ratiosByPage[leftPage] || 0;
    const rightSideRatio = onePager ? 0 : ratiosByPage[leftPage + 1] || 0;

    // Dimensions
    let height = 0;
    if (!viewportDimensions) height = 0;
    else {
      let heightFromWidth = 0;
      height = viewportDimensions.height - (isSmallScreen ? 20 : 100);
      if (onePager) heightFromWidth = viewportDimensions.width / leftSideRatio;
      else if (leftSideRatio === 0) {
        heightFromWidth = viewportDimensions.width / (2 * rightSideRatio);
      } else if (rightSideRatio === 0) {
        heightFromWidth = viewportDimensions.width / (2 * leftSideRatio);
      } else {
        heightFromWidth =
          viewportDimensions.width / (leftSideRatio + rightSideRatio);
      }
      heightFromWidth -= isSmallScreen ? 20 : 100;
      if (heightFromWidth < height) height = heightFromWidth;
    }
    let magazineWidth = (rightSideRatio + leftSideRatio) * height;
    if (!rightSideRatio || !leftSideRatio) magazineWidth *= 2;
    if (onePager) magazineWidth = leftSideRatio * height;

    const commonViewerProps = {
      withAnimation,
      backwards,
      leftPage,
      ratiosByPage,
      height,
      onRatioChange: (...args) => this.handleRatioChange(...args),
      renderMode,
      numberOfPages,
      zoomScale: zoom ? zoomScale || 1 : 1,
      enhance,
    };

    const doubleClickAnimationDuration = 200;

    return (
      <StyleWrapper>
        <TransformWrapper
          centerOnInit
          limitToBounds={false}
          doubleClick={{
            mode: zoom ? 'reset' : 'zoomIn',
            animationTime: doubleClickAnimationDuration,
          }}
          wrapperClass="react-transform-wrapper"
          ref={this.transformRef}
          panning={{
            disabled: !zoom,
          }}
          onZoom={ref => {
            if (ref.state.scale > 1) toggleZoom(true, ref.state.scale);
          }}
          onZoomStop={ref => {
            if (ref.state.scale <= 1) {
              toggleZoom(false, 1);
              ref.centerView(1);
            }
          }}
        >
          <TransformComponent>
            <PerspectiveWrapper
              onePager={onePager}
              width={magazineWidth}
              height={height}
              leftSideRatio={leftSideRatio}
              rightSideRatio={rightSideRatio}
              onTouchMove={this.handleTouchMove}
              onTouchStart={this.handleTouchStart}
              onTouchEnd={this.handleTouchEnd}
              onClick={() => {
                onClick();
                // Hacky way of detecting if we zoomed in or out by double clicking
                setTimeout(() => {
                  const scale = this.transformRef.current
                    ? this.transformRef.current.state.scale
                    : 1;
                  if (!zoom && scale > 1) toggleZoom(true, scale);
                  else if (zoom && scale <= 1) toggleZoom(false, 1);
                }, doubleClickAnimationDuration + 50);
              }}
            >
              <MagazineShadow
                height={height}
                leftSideRatio={leftSideRatio}
                rightSideRatio={rightSideRatio}
                pageNumber={leftPage}
                numberOfPages={numberOfPages}
              />
              {!onePager && <TwoPagedViewer {...commonViewerProps} />}
              {onePager && <SinglePageViewer {...commonViewerProps} />}
            </PerspectiveWrapper>
          </TransformComponent>
        </TransformWrapper>
      </StyleWrapper>
    );
  }
}

const StyleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .react-transform-wrapper {
    overflow: visible;
  }
`;

const PerspectiveWrapper = styled.div`
  user-select: none;
  height: ${props => props.height}px;
  width: ${props => props.width}px;

  .page-wrapper {
    left: ${props =>
      props.onePager
        ? 0
        : !props.leftSideRatio
        ? props.rightSideRatio * props.height
        : props.leftSideRatio * props.height}px;
  }
`;

const MagazineShadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  &::before {
    width: ${props => props.leftSideRatio * props.height}px;
    left: 0;
    transition: opacity ${props => (props.pageNumber > 0 ? '.3s .3s' : '.2s')};
    opacity: ${props => (props.pageNumber > 0 ? 1 : 0)};
  }
  &::after {
    width: ${props => props.rightSideRatio * props.height}px;
    right: 0;
    transition: opacity
      ${props => (props.pageNumber < props.numberOfPages ? '.3s .3s' : '.2s')};
    opacity: ${props => (props.pageNumber < props.numberOfPages ? 1 : 0)};
  }
`;

const TwoPagedViewer = ({
  withAnimation,
  backwards,
  leftPage,
  ratiosByPage,
  height,
  onRatioChange,
  renderMode,
  numberOfPages,
  zoomScale,
  enhance,
}) => {
  // Transitions
  const unturnedTransition = withAnimation
    ? `right-page${backwards ? '-reverse' : ''}`
    : '';
  const turnedTransition = withAnimation
    ? `left-page${backwards ? '-reverse' : ''}`
    : '';

  const leftCoverWidth = ratiosByPage[leftPage] * height;
  const rightCoverWidth = ratiosByPage[leftPage + 1] * height;

  return (
    <>
      {/* Turned Stack */}
      <CSSTransitionGroup
        transitionName={turnedTransition}
        transitionEnterTimeout={withAnimation ? (backwards ? 10 : 300) : 10}
        transitionLeaveTimeout={withAnimation ? (backwards ? 300 : 250) : 10}
      >
        {leftPage - 2 > 0 && (
          <Page
            key={`page_${leftPage - 2}`}
            pageNumber={leftPage - 2}
            height={height}
            width={ratiosByPage[leftPage - 2] * height}
            maxWidth={leftCoverWidth}
            onRatioChange={onRatioChange}
            renderMode="canvas"
            left
          />
        )}
        {leftPage > 0 && (
          <Page
            scale={zoomScale}
            enhance={enhance}
            key={`page_${leftPage}`}
            pageNumber={leftPage}
            height={height}
            width={leftCoverWidth}
            maxWidth={leftCoverWidth}
            onRatioChange={onRatioChange}
            renderMode={renderMode}
            left
          />
        )}
        {leftPage < numberOfPages - 1 && (
          <Page
            key={`page_${leftPage + 2}`}
            pageNumber={leftPage + 2}
            height={height}
            width={ratiosByPage[leftPage + 2] * height}
            maxWidth={leftCoverWidth}
            onRatioChange={onRatioChange}
            renderMode="canvas"
            left
            turned
          />
        )}
      </CSSTransitionGroup>
      {/* Unturned stack */}
      <CSSTransitionGroup
        transitionName={unturnedTransition}
        transitionLeaveTimeout={withAnimation ? (backwards ? 300 : 250) : 10}
        transitionEnterTimeout={withAnimation ? (backwards ? 300 : 10) : 10}
      >
        {leftPage - 1 > 0 && (
          <Page
            key={`page_${leftPage - 1}`}
            pageNumber={leftPage - 1}
            height={height}
            width={ratiosByPage[leftPage - 1] * height}
            maxWidth={rightCoverWidth}
            onRatioChange={onRatioChange}
            renderMode="canvas"
            turned
          />
        )}
        {leftPage < numberOfPages && (
          <Page
            scale={zoomScale}
            enhance={enhance}
            key={`page_${leftPage + 1}`}
            pageNumber={leftPage + 1}
            height={height}
            width={rightCoverWidth}
            maxWidth={rightCoverWidth}
            onRatioChange={onRatioChange}
            renderMode={renderMode}
          />
        )}
        {leftPage < numberOfPages - 2 && (
          <Page
            key={`page_${leftPage + 3}`}
            pageNumber={leftPage + 3}
            height={height}
            width={ratiosByPage[leftPage + 3] * height}
            maxWidth={rightCoverWidth}
            onRatioChange={onRatioChange}
            renderMode="canvas"
          />
        )}
      </CSSTransitionGroup>
    </>
  );
};

const SinglePageViewer = ({
  withAnimation,
  backwards,
  leftPage,
  ratiosByPage,
  height,
  onRatioChange,
  renderMode,
  numberOfPages,
  zoomScale,
  enhance,
}) => {
  console.log('zoomScale: ', zoomScale);
  const coverWidth = ratiosByPage[leftPage] * height;

  return (
    <CSSTransitionGroup
      transitionName={`one-page${backwards ? '-reverse' : ''}`}
      transitionLeaveTimeout={withAnimation ? 300 : 10}
      transitionEnterTimeout={withAnimation ? 300 : 10}
    >
      {leftPage + 1 <= numberOfPages && (
        <Page
          key={`page_${leftPage + 1}`}
          pageNumber={leftPage + 1}
          height={height}
          width={ratiosByPage[leftPage + 1] * height}
          maxWidth={coverWidth}
          onRatioChange={onRatioChange}
          renderMode="canvas"
        />
      )}
      {leftPage <= numberOfPages && (
        <Page
          key={`page_${leftPage}`}
          pageNumber={leftPage}
          height={height}
          width={coverWidth}
          maxWidth={coverWidth}
          onRatioChange={onRatioChange}
          renderMode={renderMode}
          scale={zoomScale}
          enhance={enhance}
        />
      )}
      {leftPage - 1 > 0 && (
        <Page
          key={`page_${leftPage - 1}`}
          pageNumber={leftPage - 1}
          height={height}
          width={ratiosByPage[leftPage - 1] * height}
          maxWidth={coverWidth}
          onRatioChange={onRatioChange}
          renderMode="canvas"
          turned
        />
      )}
    </CSSTransitionGroup>
  );
};

const mapStateToProps = state => ({
  zoom: state.magazine.zoom,
  zoomScale: state.magazine.zoomScale,
  pageNumber: state.magazine.pageNumber,
  backwards: state.magazine.backwards,
  numberOfPages: state.magazine.numberOfPages,
  onePager: state.magazine.onePager,
  onePagerMode: state.magazine.onePagerMode,
  viewportDimensions: state.magazine.viewportDimensions,
  isSmallScreen: state.magazine.isSmallScreen,
  isSearchMode: state.magazine.isSearchMode,
  withAnimation: state.magazine.withAnimation,
});

const ConnectedMagazine = connect(
  mapStateToProps,
  {
    nextPage: magazine.nextPage,
    previousPage: magazine.previousPage,
    setOnePager: magazine.setOnePager,
    toggleZoom: magazine.toggleZoom,
  },
  null,
  { forwardRef: true }
)(Magazine);

export default React.forwardRef((props, ref) => (
  <ConnectedMagazine {...props} ref={ref} />
));
