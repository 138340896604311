import update from 'immutability-helper';
import { createActions, handleActions } from 'redux-actions';
import store from '../../../index';

// Default state
const defaultState = {
  numberOfPages: null,
  pageNumber: 1,
  showThumbs: false,
  backwards: false,
  zoom: false,
  viewportDimensions: null,
  isSmallScreen: false,
  onePager: false,
  onePagerMode: 0,
  isSearchMode: false,
  withAnimation: true,
  searchResult: [],
  searchInput: '',
};

// Selectors

export const getSearchPageNumbers = state =>
  state.magazine.searchResult.map(x => x.page);
export const getSearchHitsByPage = state => {
  const pages = [];
  state.magazine.searchResult.forEach(page => {
    const hitsOnPage = [];
    page.hits.forEach(hit => {
      const hitsOnElement = hitsOnPage[hit.itemIndex] || [];
      hitsOnPage[hit.itemIndex] = hitsOnElement.concat({
        start: hit.highlightStart,
        end: hit.highlightEnd,
      });
    });
    pages[page.page] = hitsOnPage;
  });
  return pages;
};

// Actions

function nextPage() {
  const state = store.getState();
  const onePagerMode = state.magazine.onePagerMode;
  const onePagerArray = state.magazine.onePagerArray;

  // If onePagerArray not yet initialized, usually a delay of few secs
  if ((onePagerArray || []).length === 0) return true;

  const nextPageIndex = Math.min(
    state.magazine.numberOfPages,
    state.magazine.pageNumber + (state.magazine.onePager ? 1 : 2)
  );
  let withAnimation = true;
  if (
    onePagerArray[state.magazine.pageNumber] !== onePagerArray[nextPageIndex] &&
    onePagerMode === 3
  )
    withAnimation = false;
  return withAnimation;
}

function previousPage() {
  const state = store.getState();
  const onePagerMode = state.magazine.onePagerMode;
  const onePagerArray = state.magazine.onePagerArray;

  // If onePagerArray not yet initialized, usually a delay of few secs
  if ((onePagerArray || []).length === 0) return true;

  const prevPageIndex = Math.max(
    0,
    state.magazine.pageNumber - (state.magazine.onePager ? 1 : 2)
  );
  let withAnimation = true;
  if (
    onePagerArray[state.magazine.pageNumber] !== onePagerArray[prevPageIndex] &&
    onePagerMode === 3
  )
    withAnimation = false;
  return withAnimation;
}

export const { magazine } = createActions({
  MAGAZINE: {
    NEXT_PAGE: nextPage,
    PREVIOUS_PAGE: previousPage,
    SET_PAGE_NUMBER: pageNumber => ({ pageNumber }),
    TOGGLE_THUMBS: visible => ({ visible }),
    TOGGLE_ZOOM: (visible, zoomScale) => ({ visible, zoomScale }),
    TOGGLE_SEARCH_MODE: visible => ({ visible }),
    SET_NUMBER_OF_PAGES: numberOfPages => ({ numberOfPages }),
    SET_VIEWPORT_DIMENSIONS: (width, height) => ({
      dimensions: { width, height },
    }),
    SET_ONE_PAGER_MODE: value => ({ value }),
    SET_ONE_PAGER: value => ({ value }),
    SET_ONE_PAGER_ARRAY: value => ({ value }),
    SET_SMALL_SCREEN: value => ({ value }),
    SET_TRANSITION_DIRECTION: value => ({ backwards: value === 'backwards' }),
    SET_SEARCH_RESULT: value => ({ value }),
    SET_SEARCH_INPUT: value => ({ value }),
  },
});

// Reducer
export const magazineReducer = handleActions(
  {
    [magazine.toggleThumbs](
      state,
      {
        payload: { visible },
      }
    ) {
      const v = typeof visible === 'boolean' ? visible : !state.showThumbs;
      return update(state, {
        showThumbs: { $set: v },
      });
    },

    [magazine.toggleZoom](
      state,
      {
        payload: { visible, zoomScale },
      }
    ) {
      const v = typeof visible === 'boolean' ? visible : !state.zoom;
      return update(state, {
        zoom: { $set: v },
        zoomScale: { $set: zoomScale || 1 },
      });
    },

    [magazine.toggleSearchMode](
      state,
      {
        payload: { visible },
      }
    ) {
      const v = typeof visible === 'boolean' ? visible : !state.isSearchMode;
      return update(state, {
        isSearchMode: { $set: v },
      });
    },

    [magazine.nextPage](state, { payload: withAnimation }) {
      // prevent overscroll
      if (state.pageNumber >= state.numberOfPages) return state;
      return update(state, {
        pageNumber: {
          $set: Math.min(
            state.numberOfPages,
            state.pageNumber + (state.onePager ? 1 : 2)
          ),
        },
        withAnimation: { $set: withAnimation },
        backwards: { $set: false },
      });
    },

    [magazine.previousPage](state, { payload: withAnimation }) {
      return update(state, {
        pageNumber: {
          $set: Math.max(1, state.pageNumber - (state.onePager ? 1 : 2)),
        },
        withAnimation: { $set: withAnimation },
        backwards: { $set: true },
      });
    },

    [magazine.setPageNumber](
      state,
      {
        payload: { pageNumber },
      }
    ) {
      if (pageNumber === undefined) return state;
      const backwards = pageNumber < state.pageNumber;
      return update(state, {
        pageNumber: {
          $set: Math.max(
            1,
            Math.min(pageNumber, state.numberOfPages ?? Infinity)
          ),
        },
        backwards: { $set: backwards },
      });
    },

    [magazine.setNumberOfPages](
      state,
      {
        payload: { numberOfPages },
      }
    ) {
      return update(state, {
        numberOfPages: { $set: numberOfPages },
        pageNumber: {
          $set: Math.max(1, Math.min(state.pageNumber, numberOfPages)),
        },
      });
    },

    [magazine.setViewportDimensions](
      state,
      {
        payload: { dimensions },
      }
    ) {
      return update(state, {
        viewportDimensions: { $set: dimensions },
      });
    },

    [magazine.setOnePagerMode](
      state,
      {
        payload: { value },
      }
    ) {
      return update(state, {
        onePagerMode: { $set: value },
      });
    },

    [magazine.setOnePager](
      state,
      {
        payload: { value },
      }
    ) {
      return update(state, {
        onePager: { $set: value },
      });
    },

    [magazine.setOnePagerArray](
      state,
      {
        payload: { value },
      }
    ) {
      return update(state, {
        onePagerArray: { $set: value },
      });
    },

    [magazine.setSmallScreen](
      state,
      {
        payload: { value },
      }
    ) {
      return update(state, {
        isSmallScreen: { $set: value },
      });
    },

    [magazine.setTransitionDirection](
      state,
      {
        payload: { backwards },
      }
    ) {
      return update(state, {
        backwards: { $set: backwards },
      });
    },

    [magazine.setSearchResult](
      state,
      {
        payload: { value },
      }
    ) {
      return update(state, {
        searchResult: { $set: value },
      });
    },

    [magazine.setSearchInput](
      state,
      {
        payload: { value },
      }
    ) {
      return update(state, {
        searchInput: { $set: value },
      });
    },
  },
  defaultState
);
