import React from 'react';
import { Provider } from 'react-redux';

import './assets/css/defaults.css';
import './assets/css/transitions.css';
import App from './src';

/* eslint-disable */
/* TODO chunks
const App = LazyLoadHandler({
  loader: () =>
    import(/* webpackChunkName: "vanilla-container" * /
    './src')
});
*/
/* eslint-enable */

const Root = ({ store }) => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default Root;
