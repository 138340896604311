/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/SearchOutlined';
import HighlightOffIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIcon from '../common/icons/loading.svg';

import { parsePdf } from './common/utils';
import { magazine } from './common/ducks';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.handleSearchDebounced = debounce(500, this.handleSearch);
    this.state = {
      processing: false,
    };
  }

  handleSearch = async event => {
    const { setSearchResult, setSearchInput, file } = this.props;
    this.setState({ processing: true });
    const searchInput = event.target.value;
    const isCaseSensitive = false;

    // cancel the previous search
    if (this.cancelSearch) this.cancelSearch();
    let searchReject;
    const searchPromise =
      searchInput !== ''
        ? new Promise(async (resolve, reject) => {
            searchReject = reject;
            resolve(await parsePdf(file, searchInput, isCaseSensitive));
          })
        : new Promise(resolve => resolve([]));
    this.cancelSearch = searchReject;
    searchPromise
      .then(searchResult => {
        setSearchInput(searchInput);
        setSearchResult(searchResult);
        this.setState({ processing: false });
      })
      .catch(() => {});
  };

  handleBackClick = () => {
    const { toggleThumbs, inputRef } = this.props;
    inputRef.current.focus();
    toggleThumbs();
  };

  render() {
    const { onClose, inputRef, showThumbs } = this.props;
    const { processing } = this.state;
    return (
      <Wrapper>
        <StyledFormControl>
          <SearchInput
            id="input-with-icon-adornment"
            inputRef={inputRef}
            label="Search"
            placeholder="Search"
            autoFocus
            onChange={e => {
              e.persist();
              this.handleSearchDebounced(e);
            }}
            startAdornment={
              <InputAdornment position="start">
                {processing ? ( // eslint-disable-line no-nested-ternary
                  <StyledLoadingImg src={LoadingIcon} alt="Loading" />
                ) : showThumbs ? (
                  <SearchIcon />
                ) : (
                  <Button onClick={this.handleBackClick}>
                    <StyledArrowBackIcon />
                  </Button>
                )}
              </InputAdornment>
            }
          />
          <Button onClick={onClose}>
            <StyledHighlightOffIcon />
          </Button>
        </StyledFormControl>
      </Wrapper>
    );
  }
}

const Button = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
`;

const Wrapper = styled.div`
  position: fixed;
  align-items: center;
  flex-direction: row;
  height: 50px;
  width: 80%;
  max-width: 400px;
  background-color: white;
  border: grey 2px solid;
  border-radius: 20px 0 20px 0px;
  top: 5px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledFormControl = styled(FormControl)`
  && {
    position: absolute;
    width: 90%;
    display: flex;
    left: 20px;
    bottom: 8px;
    align-items: center;
    flex-direction: row;
    flex: 1;
    z-index: 999;
  }
`;

const SearchInput = styled(Input)`
  && {
    flex: 2;
    underline: black;
    margin-right: 6px;

    &::after {
      border-color: #f69a79;
    }
  }
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
  && {
    margin: 0;
    height: 1.2em;
    width: 1.2em;
    cursor: pointer;
  }
`;

const StyledLoadingImg = styled.img`
  animation: spin 0.8s ease;
  animation-iteration-count: infinite;
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  cursor: pointer;
`;

const mapStateToProps = state => ({
  showThumbs: state.magazine.showThumbs,
});

const ConnectedSearchBar = connect(
  mapStateToProps,
  {
    toggleThumbs: magazine.toggleThumbs,
    setSearchInput: magazine.setSearchInput,
    setSearchResult: magazine.setSearchResult,
  },
  null,
  { forwardRef: true }
)(SearchBar);

export default React.forwardRef((props, ref) => (
  <ConnectedSearchBar {...props} inputRef={ref} />
));
