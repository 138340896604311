/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Page } from 'react-pdf';
import styled from 'styled-components';
import { connect } from 'react-redux';

import CloseIcon from '@material-ui/icons/CloseOutlined';
import { magazine, getSearchPageNumbers } from './common/ducks';
import { loadDocumentInfo } from './common/utils';

const MAX_PREVIEW_SIZE = 180;
const MIN_PREVIEW_SIZE = 90;
const PREVIEW_GAP = 20;
let PREVIEW_SIZE = 160;

class Thumbnails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: 0,
      ...this.getThumbsLimits(),
      prevTouchPoint: null,
      pageRatios: null,
    };
  }

  async componentDidMount() {
    const { file } = this.props;
    const pageRatios = await loadDocumentInfo(file);
    // TODO maybe use these also with magazine pages
    this.setState({ pageRatios });
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(prevProps) {
    let { pageNumber } = this.props;
    const {
      viewportDimensions,
      searchPageNumbers,
      onePager,
      isSearchMode,
      searchInput,
    } = this.props;

    const dimensionsChanged =
      viewportDimensions !== prevProps.viewportDimensions;
    if (
      pageNumber !== prevProps.pageNumber ||
      dimensionsChanged ||
      searchPageNumbers.length !== prevProps.searchPageNumbers.length ||
      (isSearchMode && searchInput !== '') !==
        (prevProps.isSearchMode && prevProps.searchInput !== '')
    ) {
      const { thumbsPerCol, thumbsPerRow } = dimensionsChanged
        ? this.getThumbsLimits()
        : this.state;
      const pagesCount = this.getNumberOfPages();
      // showing results of the search
      if (searchPageNumbers && searchPageNumbers.length > 0) {
        pageNumber = searchPageNumbers.findIndex(x => x >= pageNumber);
        if (pageNumber === -1) pageNumber = pagesCount;
        else pageNumber += 1;
      }
      const pagination = Math.max(
        0,
        Math.min(
          Math.floor(
            (pageNumber + (onePager ? 0.5 : -0.5)) /
              (thumbsPerCol * thumbsPerRow)
          ),
          Math.floor((pagesCount - 0.5) / (thumbsPerCol * thumbsPerRow))
        )
      );
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        pagination,
        thumbsPerCol,
        thumbsPerRow,
      });
      /* eslint-enable react/no-did-update-set-state */
    }
    return true;
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = event => {
    const { showThumbs, toggleThumbs } = this.props;
    if (event.key === 'Escape' && showThumbs) {
      toggleThumbs(false);
    }
  };

  getNumberOfPages = () => {
    const {
      isSearchMode,
      searchInput,
      searchPageNumbers,
      numberOfPages,
    } = this.props;
    return isSearchMode && searchInput !== ''
      ? searchPageNumbers.length
      : numberOfPages || 0;
  };

  getThumbWidth = () => {
    return PREVIEW_SIZE + PREVIEW_GAP;
  };

  getThumbHeight = () => {
    return PREVIEW_SIZE + PREVIEW_GAP;
  };

  getThumbAdjustedHeight = pageNumber => {
    const { pageRatios } = this.state;
    if (
      !pageRatios ||
      !pageRatios[pageNumber - 1] ||
      pageRatios[pageNumber - 1] <= 1
    )
      return PREVIEW_SIZE;
    return PREVIEW_SIZE / pageRatios[pageNumber - 1];
  };

  getThumbsLimits = () => {
    const { viewportDimensions } = this.props;
    if (!viewportDimensions) return { thumbsPerCol: 0, thumbsPerRow: 0 };
    const { isSmallScreen } = this.props;
    const containerMaxWidth = viewportDimensions.width - 40;
    const containerMaxHeight =
      viewportDimensions.height - (isSmallScreen ? 105 : 135);

    // try to fit at least a 3 in a row on portrait and 5 in landscape
    const maxGridWidth = Math.floor(
      containerMaxWidth / (MIN_PREVIEW_SIZE + PREVIEW_GAP)
    );
    const maxGridHeight = Math.floor(
      containerMaxHeight / (MIN_PREVIEW_SIZE + PREVIEW_GAP)
    );
    const responsiveSize =
      Math.min(
        containerMaxWidth / Math.min(maxGridHeight <= 1 ? 5 : 3, maxGridWidth),
        containerMaxHeight / Math.min(3, maxGridHeight)
      ) - PREVIEW_GAP;
    PREVIEW_SIZE = Math.min(
      MAX_PREVIEW_SIZE,
      Math.max(MIN_PREVIEW_SIZE, responsiveSize)
    );

    const thumbsPerRow = Math.max(
      1,
      Math.floor(containerMaxWidth / this.getThumbWidth())
    );
    const thumbsPerCol = Math.max(
      1,
      Math.floor(containerMaxHeight / this.getThumbHeight())
    );
    return { thumbsPerCol, thumbsPerRow };
  };

  handleNavigationClick = diff => event => {
    event.stopPropagation();
    this.changePage(diff);
  };

  changePage = diff => {
    const { thumbsPerCol, thumbsPerRow } = this.state;
    const thumbsPerPage = thumbsPerCol * thumbsPerRow;
    this.setState(state => ({
      pagination: Math.max(
        0,
        Math.min(
          Math.ceil(this.getNumberOfPages() / thumbsPerPage) - 1,
          state.pagination + diff
        )
      ),
    }));
  };

  handleThumbClick = pageNumber => {
    const {
      setPageNumber,
      setTransitionDirection,
      pageNumber: currentPage,
    } = this.props;
    setPageNumber(pageNumber);
    setTransitionDirection(currentPage > pageNumber && 'backwards');
  };

  handleShadowClick = () => {
    const { toggleThumbs } = this.props;
    toggleThumbs(false);
  };

  handleTouchMove = event => {
    const { prevTouchPoint } = this.state;
    if (prevTouchPoint && event.touches && event.touches.length > 0) {
      const touch = event.touches[0];
      const { screenX } = touch;
      if (screenX - prevTouchPoint.x < -50) {
        this.changePage(1);
        this.setState({
          prevTouchPoint: null,
        });
      } else if (screenX - prevTouchPoint.x > 50) {
        this.changePage(-1);
        this.setState({
          prevTouchPoint: null,
        });
      }
    }
  };

  handleTouchStart = event => {
    if (!event.touches || event.touches.length === 0) return;
    const touch = event.touches[0];
    this.setState({
      prevTouchPoint: {
        x: touch.screenX,
        y: touch.screenY,
      },
    });
  };

  handleTouchEnd = () => {
    this.setState({
      prevTouchPoint: null,
    });
  };

  createThumbnail = number => {
    const { onePager, pageNumber, isSmallScreen } = this.props;
    const pageVisible = onePager
      ? pageNumber === number
      : Math.floor(pageNumber / 2) === Math.floor(number / 2);
    return (
      <Thumbnail
        className={pageVisible && 'selected'}
        onClick={() => this.handleThumbClick(number)}
        key={`thumb_${number}`}
        width={this.getThumbWidth()}
        compact={isSmallScreen}
      >
        <Page
          pageNumber={number}
          height={this.getThumbAdjustedHeight(number)}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          loading=""
        />
        <ThumbnailText>{number}</ThumbnailText>
      </Thumbnail>
    );
  };

  render() {
    const { pagination, thumbsPerCol, thumbsPerRow } = this.state;
    const {
      numberOfPages,
      showThumbs,
      isSmallScreen,
      isSearchMode,
      searchPageNumbers,
      searchInput,
    } = this.props;

    const thumbsPerPage = thumbsPerCol * thumbsPerRow;

    const actualPageCount = this.getNumberOfPages();
    const numberOfPaginations = Math.ceil(actualPageCount / thumbsPerPage);

    let filteredThumbnails;

    // If search mode is on:
    if (isSearchMode && searchInput !== '') {
      filteredThumbnails = searchPageNumbers
        .slice(pagination * thumbsPerPage, (pagination + 1) * thumbsPerPage)
        .map(number => this.createThumbnail(number));
    }

    // Otherwise:
    else {
      filteredThumbnails = Array.from(
        new Array(
          Math.min(numberOfPages - pagination * thumbsPerPage, thumbsPerPage)
        ),
        (el, i) => {
          const number = pagination * thumbsPerPage + i + 1;
          return this.createThumbnail(number);
        }
      );
    }

    return (
      <div
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        <Shadow
          show={showThumbs}
          onClick={this.handleShadowClick}
          isSmallScreen={isSmallScreen}
        >
          <StyledCloseIcon />
          {filteredThumbnails.length > 0 ? (
            <Wrapper
              width={thumbsPerRow * this.getThumbWidth()}
              height={thumbsPerCol * this.getThumbHeight()}
              thumbsPerRow={thumbsPerRow}
            >
              {filteredThumbnails}
            </Wrapper>
          ) : (
            isSearchMode && (
              <NoResultsText>
                No results found matching the search.
              </NoResultsText>
            )
          )}
          {actualPageCount > thumbsPerPage && (
            <PageNavigationWrapper>
              <p>Page</p>
              <NavigationControl onClick={this.handleNavigationClick(-1)}>
                &lt;
              </NavigationControl>
              <span>{pagination + 1}</span>
              <span>/</span>
              <span>{numberOfPaginations}</span>
              <NavigationControl onClick={this.handleNavigationClick(1)}>
                &gt;
              </NavigationControl>
            </PageNavigationWrapper>
          )}
        </Shadow>
      </div>
    );
  }
}

const Shadow = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  padding-bottom: ${props => (props.isSmallScreen ? 20 : 60)}px;
  padding-top: 40px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.thumbsPerRow}, 1fr);
  justify-items: center;
  align-items: center;
  align-content: start;
  grid-auto-rows: ${() => PREVIEW_SIZE + PREVIEW_GAP}px;
  margin: auto;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Thumbnail = styled.div`
  position: relative;
  max-width: ${props => props.width}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  border-radius: 2px;
  overflow: hidden;

  &.selected > div {
    border: 5px solid #186d8a;
  }
  &:not(.selected) > div {
    padding: 5px;
    background-color: transparent !important;
  }
`;

const ThumbnailText = styled.p`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.4);
  padding: 4px;
  margin: 0;
  line-height: 1;
  border-top-left-radius: 4px;
  color: white;
  padding-right: 2px;
`;

const PageNavigationWrapper = styled.div`
  color: white;
  font-size: 14px;
  align-self: center;

  > p {
    margin: 0;
    text-align: center;
  }
`;

const NavigationControl = styled.span`
  padding: 12px;
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const NoResultsText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
`;

const mapStateToProps = state => {
  const {
    viewportDimensions,
    numberOfPages,
    onePager,
    pageNumber,
    showThumbs,
    isSmallScreen,
    isSearchMode,
    searchInput,
  } = state.magazine;

  return {
    viewportDimensions,
    numberOfPages,
    onePager,
    pageNumber,
    showThumbs,
    isSmallScreen,
    isSearchMode,
    searchPageNumbers: getSearchPageNumbers(state),
    searchInput,
  };
};
export default connect(
  mapStateToProps,
  {
    setPageNumber: magazine.setPageNumber,
    setTransitionDirection: magazine.setTransitionDirection,
    toggleThumbs: magazine.toggleThumbs,
  }
)(Thumbnails);
