import { combineReducers } from 'redux';

// import { commonReducer as common } from './common/ducks';
import { magazineReducer as magazine } from './pdf-magazine/common/ducks';

// App reducer
const appReducer = combineReducers({
  // common,
  magazine,
});

export default appReducer;
