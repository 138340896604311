/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Page as PdfPage } from 'react-pdf';
import styled, { css } from 'styled-components';
import { isChrome } from 'react-device-detect';
import { getSearchPageNumbers, getSearchHitsByPage } from './common/ducks';

class Page extends Component {
  static props = {
    pageNumber: PropTypes.number,
    turned: PropTypes.bool,
    left: PropTypes.bool,
    onRatioChange: PropTypes.func,
    scale: PropTypes.number,
    enhance: PropTypes.bool,
  };

  state = {
    ratio: null,
    pageHeight: 0,
    annotationSizes: [],
  };

  componentDidUpdate(prevProps) {
    const { onRatioChange, pageNumber } = this.props;
    const { ratio } = this.state;
    // when on ratio change is provided for the first time, inform page's precalculated ratio
    if (!prevProps.onRatioChange && onRatioChange && ratio) {
      onRatioChange(pageNumber, ratio);
    }
  }

  highlightSearched = (str, itemIndex, pageNumber) => {
    const { searchHitsByPage } = this.props;

    if (
      str.trim().length === 0 ||
      !searchHitsByPage ||
      !searchHitsByPage[pageNumber] ||
      !searchHitsByPage[pageNumber][itemIndex]
    )
      return null;

    const hitsOnItem = searchHitsByPage[pageNumber][itemIndex].sort(
      (a, b) => b.start - a.start
    );

    // NOTE: Beware of dangerouslySetInnerHTML
    let highlightedStr = str;
    hitsOnItem.forEach(hit => {
      highlightedStr = `${highlightedStr.substring(0, hit.start)}<span style="
        background-color: rgba(241, 90, 34, 0.5);
        display: inline-block;
        transform-origin: center center;
        transform: scale(1.2);
      ">${highlightedStr.substring(
        hit.start,
        hit.end
      )}</span>${highlightedStr.substring(hit.end)}`;
    });
    return highlightedStr;
  };

  handlePageLoadSuccess = page => {
    const { view } = page;
    const { pageNumber, onRatioChange } = this.props;
    const ratio = (view[2] - view[0]) / (view[3] - view[1]);
    if (onRatioChange) onRatioChange(pageNumber, ratio);
    this.setState({ ratio, pageHeight: view[3] - view[1] });
  };

  handleGetAnnotationsSuccess = annotations => {
    const annotationSizes = annotations.map(anno => ({
      id: anno.id,
      width: anno.rect[2] - anno.rect[0],
      height: anno.rect[3] - anno.rect[1],
    }));
    this.setState({ annotationSizes });
  };

  render() {
    const {
      pageNumber,
      turned,
      height,
      onePager,
      width,
      maxWidth,
      isSearchMode,
      searchPageNumbers,
      backwards,
      renderMode,
      left,
      scale,
      enhance,
    } = this.props;
    const { pageHeight, annotationSizes, enhancedAvailable } = this.state;

    const renderTextLayer =
      isSearchMode && searchPageNumbers.includes(pageNumber);

    const maxHeight = Math.max(height, pageHeight) || height;

    const enhancedScale = scale >= 2.5 ? 4 : scale >= 1.25 ? 2 : 1;

    const annotationCss = css`
      ${annotationSizes.map(
        anno =>
          `[data-annotation-id="${anno.id}"] a {
            width: ${anno.width}px;
            height: ${anno.height}px;
          }`
      )}
    `;

    return (
      <PageWrapper
        key={`page_wrapper_${pageNumber}`}
        className={`page-wrapper ${turned ? 'turned' : ''}`}
        pageNumber={pageNumber}
        left={left}
        onePager={onePager}
        turned={turned}
        width={width || 0}
        maxWidth={maxWidth || width || 0}
        annotationCss={annotationCss}
      >
        <StyledPage
          backwards={backwards}
          turned={turned}
          scale={height / maxHeight}
          height={height}
          enhancedScale={enhancedScale}
          enhancedAvailable={enhancedAvailable}
        >
          <PdfPage
            loading=""
            className="react-pdf-page"
            pageNumber={pageNumber}
            renderMode={turned ? 'canvas' : renderMode}
            height={maxHeight}
            onLoadSuccess={this.handlePageLoadSuccess}
            onGetAnnotationsSuccess={this.handleGetAnnotationsSuccess}
            renderAnnotationLayer
            renderTextLayer={renderTextLayer}
            customTextRenderer={({ str, itemIndex }) => {
              return this.highlightSearched(str, itemIndex, pageNumber);
            }}
          />
          {enhance && enhancedScale > 1 && (
            <Enhanced
              setEnhancedAvailable={value =>
                this.setState({ enhancedAvailable: value })
              }
              scale={enhancedScale}
            >
              <PdfPage
                loading=""
                className="react-pdf-page-highres"
                pageNumber={pageNumber}
                renderMode={turned ? 'canvas' : renderMode}
                height={maxHeight * enhancedScale}
                renderAnnotationLayer
                onRenderSuccess={() => {
                  this.setState({ enhancedAvailable: true });
                }}
              />
            </Enhanced>
          )}
          {!onePager && (left ? <RotatedPageShadow /> : <PageShadow />)}
        </StyledPage>
      </PageWrapper>
    );
  }
}

const Enhanced = ({ children, setEnhancedAvailable, scale }) => {
  useEffect(
    () => {
      setEnhancedAvailable(false);
      return () => setEnhancedAvailable(false);
    },
    [scale]
  );

  return <>{children}</>;
};

const PageWrapper = styled.div`
  position: absolute;
  transform-origin: ${p => (p.left ? '100%' : '0')};
  transition: ${p =>
    p.onePager
      ? 'transform 0.3s ease, opacity 0.3s ease'
      : 'transform 0.15s 0.15s ease-out'};
  transform: none;
  opacity: 1;
  &.turned {
    ${p =>
      p.onePager
        ? 'transform: translateX(calc(-100% - 50vw)) scale(1.1); opacity: 0;'
        : 'transform: scaleX(0);'}
    ${p => !p.onePager && 'transition: transform 0.15s ease-in;'}
  }
  --index: ${props => props.pageNumber};
  z-index: calc(1000 ${p => (p.left ? '+' : '-')} var(--index));
  width: ${props => props.width}px;
  max-width: ${props => props.maxWidth}px;

  ${p => (p.left ? 'left: 0 !important;' : '')}

  .react-pdf__Page__textContent {
    & > span {
      transform: none !important;
    }
  }
  .annotationLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    [data-annotation-id] {
      position: absolute;

      a {
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    &.annotationLayer {
      ${p => p.annotationCss}
    }
  }
`;

const PageShadow = styled.div`
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05), transparent);
  width: 40px;
  z-index: 5000;
  position: absolute;
  top: 0;
  left: 0;
`;

const RotatedPageShadow = styled(PageShadow)`
  && {
    left: auto;
    right: 0;
    transform: rotate(180deg);
  }
`;

const StyledPage = styled.div`
  position: absolute;
  max-width: 100%;
  height: ${props => props.height}px;
  overflow: visible;
  z-index: ${props => (props.turned ? '-1' : '1')};
  ${() => !isChrome && 'backface-visibility: hidden; z-index: 1;'}
  & > * {
    overflow: hidden;
  }

  .react-pdf-page {
    max-height: 100%;
    min-width: auto !important;
    min-height: auto !important;
  }
  .react-pdf-page > * {
    transform-origin: 0 0;
    transform: scale(${p => p.scale || 1});
    &.react-pdf__Page__textContent {
      transform: rotate(0deg) scale(${p => p.scale || 1}) !important;
    }
  }
  .react-pdf-page-highres {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
      scale(${p => (p.scale || 1) / p.enhancedScale});
    visibility: ${p => (p.enhancedAvailable ? 'visible' : 'hidden')};
  }
`;

const mapStateToProps = state => ({
  onePager: state.magazine.onePager,
  isSearchMode: state.magazine.isSearchMode,
  searchPageNumbers: getSearchPageNumbers(state),
  searchHitsByPage: getSearchHitsByPage(state),
  viewportDimensions: state.magazine.viewportDimensions,
  backwards: state.magazine.backwards,
});

export default connect(mapStateToProps)(Page);
