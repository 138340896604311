/* eslint-disable no-cond-assign */
import { pdfjs } from 'react-pdf';

export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]]/g, '\\$&');
};

const pageIndexToHighlightIndexes = (startIndex, elemBorders, match) => {
  const endIndex = startIndex + match.length;
  const startElemIndex = elemBorders.findIndex(x => x > startIndex) - 1;
  const endElemIndex = elemBorders.findIndex(x => x > endIndex) - 1;
  const startHighlightIndex = startIndex - elemBorders[startElemIndex];
  const endHighlightIndex = endIndex - elemBorders[endElemIndex];

  const highlights = [];
  for (let i = startElemIndex; i <= endElemIndex; i += 1) {
    highlights.push({
      itemIndex: i,
      highlightStart: i === startElemIndex ? startHighlightIndex : 0,
      highlightEnd: i === endElemIndex ? endHighlightIndex : Infinity,
    });
  }
  return highlights;
};

// TODO: Examine need for escapeRegExp for text, currently only used for searchInput
const searchPageNumber = (elems, searchInput, pageNumber, isCaseSensitive) => {
  const text = elems.join(' '); // TODO: join with space or without
  const elemBorders = elems.reduce(
    (acc, cur) => acc.concat(acc[acc.length - 1] + cur.length + 1),
    [0]
  );
  const regExFlag = isCaseSensitive ? 'g' : 'gi';
  const reg = new RegExp(
    escapeRegExp(searchInput).replace(' ', '\\s+'),
    regExFlag
  );
  const foundMatches = [];
  let hit = reg.exec(text);
  while (hit !== null) {
    foundMatches.push(
      ...pageIndexToHighlightIndexes(hit.index, elemBorders, hit[0])
    );
    // foundMatches.push(pageNumber);
    hit = reg.exec(text);
  }
  if (foundMatches.length === 0) return null;
  return {
    page: pageNumber,
    hits: foundMatches,
  };
};

const parsePage = async (page, searchInput, pageNumber, isCaseSensitive) => {
  return page.then(_page => {
    const textContent = _page.getTextContent();
    return textContent.then(pageContent => {
      const pageElems = pageContent.items.map(element => {
        return element.str;
      });
      return searchPageNumber(
        pageElems,
        searchInput,
        pageNumber,
        isCaseSensitive
      );
    });
  });
};

export const parsePdf = async (pdfUrl, searchInput, isCaseSensitive) => {
  const pdf = pdfjs.getDocument(pdfUrl).promise;
  return pdf.then(_pdf => {
    const maxPages = _pdf.numPages;
    const pagePromises = []; // collecting all page promises
    for (let pageNumber = 1; pageNumber <= maxPages; pageNumber += 1) {
      const page = _pdf.getPage(pageNumber);
      pagePromises.push(
        parsePage(page, searchInput, pageNumber, isCaseSensitive)
      );
    }
    return Promise.all(pagePromises).then(foundMatches => {
      const matchedPageNumbers = foundMatches.reduce((acc, cur) => {
        if (cur) return acc.concat(cur);
        return acc;
      }, []);
      return matchedPageNumbers;
    });
  });
};

// TODO: Examine need for escapeRegExp for text, currently only used for searchInput
// TODO: Test if works for finding substring in rendered text layer (react-pdf)
export const searchIndex = (text, searchInput, isCaseSensitive) => {
  const regExFlag = isCaseSensitive ? 'g' : 'gi';
  const reg = new RegExp(escapeRegExp(searchInput), regExFlag);
  const matchedIndices = [];
  let result;
  while ((result = reg.exec(text)) !== null) {
    matchedIndices.push(result.index);
  }
  if (matchedIndices.length === 0) return null;
  return matchedIndices;
};

// TODO: Maybe preload text content here and not with every search
export const loadDocumentInfo = async pdfUrl => {
  return pdfjs.getDocument(pdfUrl).promise.then(pdf => {
    const pagePromises = [];
    const pageCount = pdf.numPages;
    for (let i = 1; i <= pageCount; i += 1) {
      pagePromises.push(
        pdf
          .getPage(i)
          .then(page => page._pageInfo.view[2] / page._pageInfo.view[3])
      );
    }
    return Promise.all(pagePromises);
  });
};
