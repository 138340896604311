import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { magazine } from './common/ducks';

class PageNavigationNextComponent extends React.Component {
  handleNextPage = () => {
    const { nextPage } = this.props;
    nextPage();
  };

  render() {
    const { showThumbs, pageNumber, numberOfPages, onePager } = this.props;
    const rightPage = onePager
      ? pageNumber
      : Math.floor(pageNumber / 2) * 2 + 1;
    // hide if showing thumbs or on the last page
    if (showThumbs || rightPage >= numberOfPages) {
      return null;
    }
    return <StyledNextPageIcon type="button" onClick={this.handleNextPage} />;
  }
}

// eslint-disable-next-line react/no-multi-comp
class PageNavigationPreviousComponent extends React.Component {
  handlePrevPage = () => {
    const { previousPage } = this.props;
    previousPage();
  };

  render() {
    const { showThumbs, pageNumber } = this.props;
    // hide if showing thumbs or on the first page
    if (showThumbs || pageNumber <= 1) return null;
    return (
      <StyledPreviousPageIcon type="button" onClick={this.handlePrevPage} />
    );
  }
}

const PageNavigationIcon = styled.button`
  display: inline-flex;
  padding: 10px;
  border: 2px solid black;
  background: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  outline-offset: 5px;
  -webkit-tap-highlight-color: transparent;

  &::after,
  &::before {
    content: '';
    display: inline-block;
    height: 16px;
    width: 2px;
    background: black;
  }
  &::before {
    transform: translateX(10px) rotate(45deg);
  }
  &::after {
    transform: translate(8px, 10.3px) rotate(-45deg);
  }
`;

const StyledPreviousPageIcon = styled(PageNavigationIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`;

const StyledNextPageIcon = styled(PageNavigationIcon)`
  transform: translateY(-50%) rotate(180deg);
  position: absolute;
  top: 50%;
  right: 10px;
`;

const mapStateToPropsNext = state => ({
  showThumbs: state.magazine.showThumbs,
  pageNumber: state.magazine.pageNumber,
  numberOfPages: state.magazine.numberOfPages,
  onePager: state.magazine.onePager,
});

const mapStateToPropsPrevious = state => ({
  showThumbs: state.magazine.showThumbs,
  pageNumber: state.magazine.pageNumber,
});

export const PageNavigationNext = connect(
  mapStateToPropsNext,
  {
    nextPage: magazine.nextPage,
  }
)(PageNavigationNextComponent);

export const PageNavigationPrevious = connect(
  mapStateToPropsPrevious,
  {
    previousPage: magazine.previousPage,
  }
)(PageNavigationPreviousComponent);
