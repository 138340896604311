import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Import some devtools
import { AppContainer } from 'react-hot-loader'; // eslint-disable-line
import { createLogger } from 'redux-logger'; // eslint-disable-line
import { createDevTools } from 'redux-devtools'; // eslint-disable-line
import LogMonitor from 'redux-devtools-log-monitor'; // eslint-disable-line
import DockMonitor from 'redux-devtools-dock-monitor'; // eslint-disable-line

import appReducer from './src/reducer.js';
import appSagas from './src/sagas.js';
import Root from './index.root'; // eslint-disable-line

// Create store
const sagaMiddleware = createSagaMiddleware();

let store; // eslint-disable-line

if (process.env.NODE_ENV === 'development') {
  // Development
  const logger = createLogger();
  /* eslint-disable */
  const devtools = window.devToolsExtension
    ? window.devToolsExtension()
    : createDevTools(
        <DockMonitor
          toggleVisibilityKey="ctrl-h"
          changePositionKey="ctrl-w"
          defaultIsVisible={false}
        >
          <LogMonitor />
        </DockMonitor>
      ).instrument();
  /* eslint-enable */
  store = createStore(
    appReducer,
    compose(
      applyMiddleware(sagaMiddleware, logger),
      devtools
    )
  );
} else {
  // Production
  store = createStore(appReducer, applyMiddleware(sagaMiddleware));
}
sagaMiddleware.run(appSagas);

// where to mount on page
const appElement = document.getElementById('app');

const renderApp = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component store={store} />
    </AppContainer>,
    appElement
  );
};

if (process.env.NODE_ENV === 'development') {
  renderApp(Root);
  if (module.hot) {
    module.hot.accept('./src', () => {
      renderApp(Root);
    });
  }
} else {
  ReactDOM.render(<Root store={store} />, appElement);
}

export default store;
