/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import { Document, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import screenfull from 'screenfull';
import { isMobile, isMobileSafari } from 'react-device-detect';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import Switch from '@material-ui/core/Switch';
import DownloadIcon from '@material-ui/icons/PublishOutlined';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNewSharp';
import ThumbnailsIcon from '../common/icons/thumbnails-icon.svg';
import SearchOffIcon from '../common/icons/search-off-icon.svg';
import ZoomInIcon from '../common/icons/zoom-in-icon.svg';
import ZoomOutIcon from '../common/icons/zoom-out-icon.svg';

import Thumbnails from './Thumbnails';
import Magazine from './Magazine';
import SearchBar from './SearchBar';
import { PageNavigationNext, PageNavigationPrevious } from './PageNavigation';
import {
  DocumentLoadingMessage,
  DocumentErrorMessage,
} from './DocumentStatusMessage';
import { magazine } from './common/ducks';
import { SearchWarningMessage } from './SearchWarningMessage';
import { loadDocumentInfo } from './common/utils';

let samplePdfLapti;
let samplePdfMedium;
let samplePdfGredi;
let samplePdfSlow;
let samplePdfLarge;
let samplePdfLinks;
let samplePdfSitowise;
let samplePdfStockmann;

/* eslint-disable global-require */
if (process.env.NODE_ENV === 'development') {
  samplePdfLapti = require('../../samples/sampleLapti.pdf');
  samplePdfMedium = require('../../samples/sampleMedium.pdf');
  samplePdfGredi = require('../../samples/sampleGredi.pdf');
  samplePdfSlow = require('../../samples/sampleSlow.pdf');
  samplePdfLarge = require('../../samples/sampleLarge.pdf');
  samplePdfLinks = require('../../samples/sampleLinks.pdf');
  samplePdfSitowise = require('../../samples/sampleSitowise.pdf');
  samplePdfStockmann = require('../../samples/sampleStockmann.pdf');
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${
  pdfjs.version
}/legacy/build/pdf.worker.min.js`;

const isDevEnv = process.env.NODE_ENV === 'development';

let uploadOwnDocuments = false;

class PdfViewer extends Component {
  magazineRef = React.createRef();

  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();

    const urlParams = window.location.search
      .substr(1)
      .split('&')
      .map(x => x.split('='));
    const fileParam = urlParams.find(x => x[0] === 'file');
    // JSP-file will put the content url in `window.fileUrl`
    let file =
      window.fileUrl || (fileParam && decodeURIComponent(fileParam[1]));
    if (!fileParam && isDevEnv) file = samplePdfLinks;

    const enhance = urlParams.find(x => x[0] === 'enhance');
    const upload = urlParams.find(x => x[0] === 'upload');
    const name = urlParams.find(x => x[0] === 'name');
    const urlOnePager = urlParams.find(x => x[0] === 'pageview');
    if (upload) uploadOwnDocuments = true;
    const pageNumber = urlParams.find(x => x[0] === 'page');
    if (pageNumber && Number(pageNumber[1]) >= 0 && props.setPageNumber)
      props.setPageNumber(Number(pageNumber[1]));
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
    window.addEventListener('load', this.handleResize);
    document.addEventListener('keydown', this.handleKeyDown, false);

    // prevent ios safari zooming and scrolling
    document.addEventListener('gesturestart', e => e.preventDefault(), {
      passive: false,
    });
    document.addEventListener('touchmove', e => e.preventDefault(), {
      passive: false,
    });

    const toggleStates = { 1: true, 2: false, 3: undefined };
    const onePageToggle =
      urlOnePager && urlOnePager[1] && toggleStates[urlOnePager[1]];

    this.state = {
      file,
      isToolbarVisible: true,
      isSearchBarVisible: false,
      documentName: name && decodeURIComponent(name[1]),
      onePageToggle,
      // 0, 2 or undef: two page, 1: one page, 3: auto
      urlOnePager:
        (urlOnePager || []).length > 1 ? parseInt(urlOnePager[1], 10) : 0,
      enhance: !enhance || enhance[1] !== 'false',
    };
    this.clickCount = 0;
  }

  componentDidMount() {
    if (screenfull && screenfull.enabled) {
      screenfull.on('error', event => {
        console.error('Failed to enable fullscreen', event);
      });
      screenfull.on('change', () => {
        this.forceUpdate();
      });
    }
  }

  componentWillUnmount() {
    if (screenfull && screenfull.enabled) {
      screenfull.off('error');
      screenfull.off('change');
    }
  }

  handleSearchButton = () => {
    const { isSearchBarVisible } = this.state;
    const {
      toggleThumbs,
      toggleSearchMode,
      setSearchInput,
      setSearchResult,
    } = this.props;

    this.setState({
      isSearchBarVisible: !isSearchBarVisible,
    });
    toggleSearchMode();
    if (!isSearchBarVisible) toggleThumbs(true);
    else {
      toggleThumbs(false);
      setSearchInput('');
      setSearchResult([]);
    }

    if (!isSearchBarVisible)
      setTimeout(() => this.searchInputRef.current.focus(), 200);
  };

  handleKeyDown = e => {
    const { showThumbs, nextPage, previousPage } = this.props;
    const { isSearchBarVisible } = this.state;
    if ((e.ctrlKey || e.metaKey) && e.key === 'f') {
      e.preventDefault();
      this.handleSearchButton();
    }
    if (e.key === 'Enter' && isSearchBarVisible && isMobile) {
      this.searchInputRef.current.blur();
    }
    if (!showThumbs && document.activeElement !== this.searchInputRef.current) {
      if (e.key === 'ArrowLeft') previousPage();
      if (e.key === 'ArrowRight') nextPage();
    }
  };

  handleResize = () => {
    // TODO: Call handleResize on input blur, to ensure proper dimensions.
    // Relevant mostly if device rotated with input focused.
    if (document.activeElement === this.searchInputRef.current && isMobile) {
      return;
    }
    const {
      setViewportDimensions,
      setOnePagerMode,
      setSmallScreen,
    } = this.props;
    const { onePageToggle, urlOnePager } = this.state;

    const screenWidth = isMobileSafari
      ? window.innerWidth
      : document.documentElement.clientWidth;
    const screenHeight = isMobileSafari
      ? window.innerHeight
      : document.documentElement.clientHeight;

    // set body height. Necessary for some ios Safari versions
    window.document.body.style.height = `${screenHeight}px`;
    window.scroll(0, 0);

    setViewportDimensions(screenWidth, screenHeight);
    setSmallScreen(screenHeight <= 500 || screenWidth <= 500);
    setOnePagerMode(
      onePageToggle !== undefined ? Number(onePageToggle) : urlOnePager
    );
  };

  handleOnePagerSwitch = event => {
    this.setState({ onePageToggle: event.target.checked }, () => {
      this.handleResize();
    });
  };

  onDocumentLoadSuccess = async loadedFile => {
    const { file } = this.state;
    const { setNumberOfPages, setOnePagerArray } = this.props;
    const { numPages } = loadedFile;
    setNumberOfPages(numPages);

    const pageRatios = await loadDocumentInfo(file);
    const onePagerArray = pageRatios.map(elem => elem > 1);
    setOnePagerArray(onePagerArray);
  };

  handleFileRead = async e => {
    const file = e.target.result;
    this.setState({ file });
  };

  handleChangeFile = e => {
    const fileReader = new FileReader();
    fileReader.onloadend = this.handleFileRead;
    if (e.target.files && e.target.files[0]) {
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  handleMagazineClick = () => {
    const { isToolbarVisible } = this.state;
    this.setState({
      isToolbarVisible: !isToolbarVisible,
    });
  };

  handleFullscreenClick = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.setState(state => state);
    }
  };

  handleMagazineClicks = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.clickCount += 1;
    this.timeout = setTimeout(() => {
      if (this.clickCount === 1) {
        this.setState(state => ({
          isToolbarVisible: !state.isToolbarVisible,
        }));
      }
      this.clickCount = 0;
    }, 250);
  };

  handleZoomClick = () => {
    const { toggleZoom } = this.props;
    if (
      this.magazineRef.current &&
      this.magazineRef.current.transformRef.current
    ) {
      const transform = this.magazineRef.current.transformRef.current;
      const zoomedOut = transform.state.scale <= 1;
      if (zoomedOut) {
        transform.zoomIn(0.7);
        toggleZoom(true, 0.7);
      } else {
        transform.centerView(1);
        toggleZoom(false, 1);
      }
    }
  };

  handleInternalLinkClick = ({ pageNumber }) => {
    if (Number.isNaN(pageNumber)) return;
    const { setPageNumber } = this.props;
    setPageNumber(pageNumber);
  };

  render() {
    const {
      file,
      isToolbarVisible,
      isSearchBarVisible,
      documentName,
      onePageToggle,
      enhance,
    } = this.state;
    const { toggleThumbs, isSmallScreen, showThumbs, zoom } = this.props;

    return (
      <>
        <Options>
          {(isDevEnv || uploadOwnDocuments) && (
            <input type="file" accept="pdf" onChange={this.handleChangeFile} />
          )}
          <Label>Single page: </Label>
          <Switch
            checked={onePageToggle}
            onChange={this.handleOnePagerSwitch}
          />
        </Options>
        <Document
          file={file}
          onLoadSuccess={this.onDocumentLoadSuccess}
          onLoadError={e => console.log(e)}
          loading={<DocumentLoadingMessage name={documentName} />}
          error={<DocumentErrorMessage />}
          onItemClick={this.handleInternalLinkClick}
          externalLinkTarget="_blank"
          className="react-pdf-document"
        >
          <Magazine
            onClick={this.handleMagazineClicks}
            ref={this.magazineRef}
            enhance={enhance}
          />
          <PageNavigationPrevious handleResize={this.handleResize} />
          <PageNavigationNext handleResize={this.handleResize} />

          <CSSTransitionGroup
            transitionName="toolbox"
            transitionEnterTimeout={400}
            transitionLeaveTimeout={400}
          >
            {(!isSmallScreen || (isToolbarVisible && !showThumbs)) && (
              <ButtonContainer>
                <Button onClick={toggleThumbs} title="Thumbnails">
                  <img src={ThumbnailsIcon} alt="Thumbnails" />
                </Button>
                {zoom ? (
                  <Button onClick={this.handleZoomClick} title="Zoom out">
                    <img src={ZoomOutIcon} alt="Zoom out" />
                  </Button>
                ) : (
                  <Button onClick={this.handleZoomClick} title="Zoom in">
                    <img src={ZoomInIcon} alt="Zoom in" />
                  </Button>
                )}
                {screenfull.enabled &&
                  (!screenfull.isFullscreen ? (
                    <FullscreenIcon
                      type="button"
                      onClick={this.handleFullscreenClick}
                      titleAccess="Fullscreen"
                    />
                  ) : (
                    <FullscreenExitIcon
                      type="button"
                      onClick={this.handleFullscreenClick}
                      titleAccess="Exit fullscreen"
                    />
                  ))}
                {window.location !== window.parent.location && (
                  <Link
                    href={window.location.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <OpenInNewIcon
                      type="button"
                      titleAccess="Open in a new window"
                    />
                  </Link>
                )}
                <Link href={file} download>
                  <StyledDownloadIcon
                    type="button"
                    titleAccess="Download PDF"
                  />
                </Link>
                {isSearchBarVisible ? (
                  <Button onClick={this.handleSearchButton} title="Search off">
                    <img src={SearchOffIcon} alt="Search off" />
                  </Button>
                ) : (
                  <Button onClick={this.handleSearchButton} title="Search">
                    <SearchIcon />
                  </Button>
                )}
              </ButtonContainer>
            )}
          </CSSTransitionGroup>
          <CSSTransitionGroup
            transitionName="searchbar"
            transitionEnterTimeout={600}
            transitionLeaveTimeout={600}
          >
            {isSearchBarVisible && (
              <SearchBar
                file={file}
                onClose={this.handleSearchButton}
                ref={this.searchInputRef}
              />
            )}
          </CSSTransitionGroup>
          <Thumbnails file={file} />
          {isSearchBarVisible && !showThumbs && <SearchWarningMessage />}
        </Document>
      </>
    );
  }
}

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  padding-bottom: ${() => (isMobileSafari ? '10px' : '3px')};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  & > * {
    cursor: pointer;
  }

  svg {
    width: 30px;
    height: 30px;
    margin: 0px 5px;
    fill: black;
  }

  img {
    max-width: none;
    height: 30px;
    width: 30px;
    margin: 0px 5px;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  && {
    transform: rotate(180deg);
    padding: 0;
  }
`;

const Options = styled.div`
  position: absolute;
  top: 0;
  right: 0.5rem;
  max-width: 500px;
`;

const Label = styled.span``;

const Link = styled.a`
  color: black;

  svg {
    padding: 2px;
  }
`;

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

const mapStateToProps = state => ({
  isSmallScreen: state.magazine.isSmallScreen,
  showThumbs: state.magazine.showThumbs,
  zoom: state.magazine.zoom,
  viewportDimensions: state.magazine.viewportDimensions,
  isSearchMode: state.magazine.isSearchMode,
  pageNumber: state.magazine.pageNumber,
  numberOfPages: magazine.numberOfPages,
});

export default connect(
  mapStateToProps,
  {
    nextPage: magazine.nextPage,
    previousPage: magazine.previousPage,
    toggleThumbs: magazine.toggleThumbs,
    toggleZoom: magazine.toggleZoom,
    toggleSearchMode: magazine.toggleSearchMode,
    setNumberOfPages: magazine.setNumberOfPages,
    setOnePagerMode: magazine.setOnePagerMode,
    setOnePagerArray: magazine.setOnePagerArray,
    setViewportDimensions: magazine.setViewportDimensions,
    setSmallScreen: magazine.setSmallScreen,
    setSearchInput: magazine.setSearchInput,
    setSearchResult: magazine.setSearchResult,
    setPageNumber: magazine.setPageNumber,
  }
)(PdfViewer);
